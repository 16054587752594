.size-small {
  --button-size: 24px;
  --button-font-size: 12px;
}
.size-normal {
  --button-size: 32px;
  --button-font-size: var(--dimensions-font-size-normal);
}
.size-large {
  --button-size: 48px;
  --button-font-size: 16px;
}
.color-primary {
  --button-main-color: var(--palette-text-primary);
  --button-text-color: var(--palette-primary-contrast-text);
}
.color-secondary {
  --button-main-color: var(--palette-secondary-main);
  --button-text-color: var(--palette-secondary-contrast-text);
}
.color-accent {
  --button-main-color: var(--palette-accent-main);
  --button-text-color: var(--palette-accent-contrast-text);
}

.root {
  padding: 0.25rem calc(var(--button-size) / 2);
  min-height: var(--button-size);
  min-width: calc(var(--button-size) * 2);
  font-size: var(--button-font-size);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  transition-duration: var(--animation-duration);
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.3;
  }
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.3;
}

.type-primary {
  border-radius: var(--button-size);
  background-color: var(--button-main-color);
  color: var(--button-text-color);
  border: 1px solid var(--button-main-color);
  transition-property: background-color, color;
  &:hover {
    background-color: var(--palette-background-default);
    color: var(--button-main-color);
    box-shadow: var(--shadow-default);
  }
}
.type-secondary {
  border-radius: var(--button-size);
  background-color: var(--palette-primary-contrast-text);
  border: 1px solid var(--palette-primary-main);
  color: var(--button-main-color);
  transition-property: background-color, color, border;
  &:hover {
    background-color: var(--palette-primary-main);
    border: 1px solid var(--button-main-color);
    box-shadow: var(--shadow-default);
    color: var(--palette-primary-contrast-text);
  }
}
.type-tertiary {
  color: var(--button-main-color);
  padding: 0.25rem 0;
  border-radius: 4px;
  border: 0;
  background-color: none;
  transition-property: opacity;
  opacity: 1;
  &:hover {
    background-color: inherit;
    opacity: 0.5;
    color: var(--palette-text-primary);
  }
}
.type-text {
  padding: 0;
  font-weight: normal;
}
